.table {
    display: table;
}

.table-row {
    display: table-row;
}

.table-cell {
    display: table-cell;
    padding: 0 0.2rem;
}

.table-cell input {
    width: 12ch;
}

input:read-only {
    background-color: lightgrey;
}

.aboveLimits input {
    background-color: red;
}

.outsideLimits {
    background-color: red;
}

.Flexbox {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    grid-gap: 1rem;
}

.InfoGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0 1rem;
}

.InfoGrid div:nth-child(2n+1) {
    margin-bottom: auto;
}

.InfoGrid div:nth-child(2n) {
    margin-top: auto;
}
